<template>

  <router-link :class="['card', desktopAuto ? 'card-desktop' : '']" :to="{ name: 'TripView', params: {id: collection.id } }">

    <span class="alignment"></span>
    <div class="card-image" :style="getStyle"></div>
    <div class="card-body">
      <bookmark-button v-if="false" :parentClass="'card-body-icon'"/>
      <h5>{{ collection.name }}</h5>
      <h6 class="info-text">{{ numberOfContents }} Destinationen</h6>
    </div>

  </router-link>
</template>

<script>
  import { bus } from '@/main';
  import { getCategoryPlaceholderImage } from '@/utils/tripmeister-helpers';
  import { json_decode } from '@/utils/helpers';

  export default {
    name: 'ReiseplanCard',
    components: {
      BookmarkButton: () => import('@/components/controls/BookmarkButton'),
    },
    props: {
      collection: {
        type: Object,
        required: true
      },
      desktopAuto: {
        type: Boolean,
      default: false
      },
      icon: {
        type: String,
      default: 'map',
        validator: icon => ['map', 'edit'].indexOf(icon) !== -1
      }
    },
    computed: {
      imageHeight: function() {
        return this.isSmall ? '105px' : '210px';
      },
      checkSafari(){
        if(this.isSafari&&this.hasPath("footPath")){
          return true;
        }
        return false;
      },
      numberOfContents(){
        if(this.collection!=null && Object.prototype.hasOwnProperty.call(this.collection,'contents')){
          return this.collection.contents.length;
        }
        return 0;
      },
      collectionImage: function() {
        if(this.collection!=null && Object.prototype.hasOwnProperty.call(this.collection,'image') && this.collection.image!=null){
          var logo = this.collection.image;
          logo = json_decode(logo);

          if(typeof logo === "object"){
            if (logo.path.indexOf('https') !== -1) {
              return logo.path;
            }
            else {
              return this.$backendUrl+logo.path;
            }
          }
          else{
            if (logo.indexOf('https') !== -1) {
              return logo;
            }
            else {

             logo = logo.charAt(0)!="/"?"/"+logo:logo;
             return this.$backendUrl+logo;
           }
         }
       }
       return this.getCategoryPlaceholderImage();
     },
     getStyle(){
      return 'backgroundImage: url("'+this.collectionImage+'")';
    }
  },
  methods: {
    getCategoryPlaceholderImage,
    json_decode,
    showReiseplanModal () {
      bus.$emit('reiseplan-modal', true)
    },
    getBackgroundImage(){
      return 'background-image: url("' + this.collectionImage + '"); max-height:'+ this.imageHeight;
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .card {
    position: relative;
    border: none;
    box-shadow: 2px 2px 5px rgba($color: $dark-color, $alpha: 0.35);
    border-radius: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: 300px;
    width: 400px;
    &-desktop {
      width: 100%;
      @include responsive($scroll-breakpoint) {
        width: 400px;
        min-width: 250px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @include responsive($scroll-breakpoint) {
      &:first-child {
        margin-left: 15px;
      }
      &:last-child {
        margin-right: 15px;
      }
    }

    &-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      min-height: 150px;
      max-width: 100%;
      max-height: 150px;
      overflow: hidden;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;

      img {
        min-width: 100%;
        height: 150px;
        width: auto;
      }
    }

    &-body {
      position: relative;
      padding: 1rem 0.5rem;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;

      &-icon {
        position: absolute;
        background: rgba($color: $white-color, $alpha: 1.0);
        box-shadow: 2px 2px 5px rgba($color: $dark-color, $alpha: 0.35);
        border-radius: 50%;
        height: 50px;
        width: 50px;
        right: 8px;
        top: -25px;
        padding: 5px;
        .icon {
          text-align: center;
          border: 1px solid $dark-color;
          border-radius: 50px;
          height: 40px;
          width: 40px;
          display: inline-block;
          line-height: 36px;
          text-align: center;
          font-size: 24px;
          color: $dark-color;

          &.edit {
            border-color: transparent;
            color: $text-color;
          }
        }
      }

      h5 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
      }

      .time-text {
        font-size: 12px;
        font-weight: 400;
        color: $dark-color;
        margin-bottom: 10px;
      }

      .info-text {
        margin-top: auto;
        font-size: 12px;
        text-transform: uppercase;
        color: $primary;
      }
    }
  }
</style>
